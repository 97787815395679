$imgPath: '/assets/all-in-one-theme/images';
$fontPath: '/assets/all-in-one-theme/fonts';
$themeVariantImgPath: '/assets/all-in-one-theme/images/theme-variants/bringo-romania-theme';
$colors: ('primary': red, 'color_1': #4A397E, 'color_2': #FFB600, 'color_3': #2DA771, 'color_4': #6D6D6D, 'color_5': #F42626, 'color_6': #EAB818, 'color_7': #FAB72C, 'color_8': #F5F5F5, 'color_9': #E9B818, 'color_10': #362768, 'color_11': rgba(74, 57, 126, 0.85), 'color_12': rgba(74, 57, 126, 1), 'color_13': #FFB600, 'color_14': #FFB600, 'color_15': #362768, 'color_16': #273C51);
$header: ('logo_img': 'bringo-logo.svg', 'logo_img_mobile': 'bringo-logo.svg', 'logo_width': 146px, 'logo_height': 46px, 'logo_width_mobile': 120px, 'logo_height_mobile': 38px, 'nav_width': 125px, 'nav_height': 40px, 'nav_margin_top': 0, 'nav_bg_img': 'bringo-logo.svg', 'hamburger_position_top': 27px);
$units: ('hamburger_menu_icon_top': 10px, 'nav_center_column_mt': 0, 'logo_width': 154px);
$fonts: ('default': 'Manrope');
.hamburger-menu-icon {
    background-image: url($themeVariantImgPath + '/menu/hamburger-menu-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: none;
    width: 24px;
    height: 24px;
    position: absolute;
    top: map-get($units, 'hamburger_menu_icon_top');
    left: 25px;
    z-index: 100;
}
.hamburger-menu-close-icon {
    background-image: url($themeVariantImgPath + '/menu/hamburger-menu-close-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 40px;
    left: 40px;
    z-index: 100;
}
.user-avatar-white-icon {
    background-image: url($themeVariantImgPath + '/menu/user-avatar-white-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.hamburger-menu-mobile {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background: map-get($colors, 'color_1');
    align-items: flex-start;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s linear;
}

body.okMarket-theme {
    .hamburger-menu-mobile:not(.big-menu) .site-logo {
        background-image: url($imgPath + "/ok-market-logo-white-small.png");
        width: 146px;
        height: 26px;
        margin-top: 10px;
    }
}

.hamburger-menu-mobile.on {
    display: flex;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.2s linear;
}
.hamburger-menu-open {
    overflow: hidden;
}
.hamburger-menu-mobile .header .logo {
    top: 30px;
    left: 0;
    right: 0;
    display: inline-flex;
    position: absolute;
    z-index: 5;
    margin: auto;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.hamburger-menu-mobile ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 140px;
}
.hamburger-menu-mobile ul li {
    display: flex;
    padding: 10px 0;
}
.hamburger-menu-mobile ul li a {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF!important;
    text-decoration: none!important;
}
.hamburger-menu-mobile .my-account .fullname {
    background: map-get($colors, 'color_1');
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #FFFFFF;
    padding: 18px 15px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.hamburger-menu-mobile .my-account ul {
    margin-top: 0;
}
.hamburger-menu-mobile .my-account ul li {
    width: 100%;
    justify-content: center;
}
.hamburger-menu-mobile .my-account ul li.logout-btn {
    border-top: 1px solid rgba(255, 255, 255, 0.6);
    padding-top: 20px;
    padding-bottom: 10px;
    margin-top: 10px;
}
.hamburger-menu-mobile .my-account ul li a {
    font-size: 20px;
    line-height: 26px;
}
.hamburger-menu-mobile ul li.active a {
    color: map-get($colors, 'color_6')!important;
}
.hamburger-menu-mobile .my-account {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 15px;
    left: 0;
    right: 0;
    z-index: 50;
    background: map-get($colors, 'color_6');
    padding: 15px;
}
.hamburger-menu-mobile .my-account > a {
    background: map-get($colors, 'color_1');
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #FFFFFF;
    padding: 18px 15px;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none!important;
}
.hamburger-menu-mobile .my-account .create-account-div span {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}
.hamburger-menu-mobile .my-account .create-account-div a {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: map-get($colors, 'color_1');
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    text-decoration: none!important;
}

@media (max-width: 767px){
    .hamburger-menu-icon {
        display: flex;
    }
    nav ul {
        display: none;
    }
    nav .dropdown.bringo-my-account {
        display: none;
    }
}
